import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import VERIFY_ICON  from '../../assets/verify-phone.png'
import Icon from "../Icon";
import Input from "../Input";
import Button from "../Button";
import { isPhoneNumber } from "../../utils/string";
import useAuth from "../../hooks/useAuth";
import QRModal from "../../pages/Unbox/QRModal";
import VietNamIcon from "../../assets/vietnam.svg"
export default function AuthModal({isOpen, onClose, onSuccess}: {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
}) {
  const {t} = useTranslation()
  const {triggerLoginByPhone, token, isLoggedIn} = useAuth()
  const [phone, setPhone] = useState('')

  const [showQRModal, setShowQRModal] = useState(false)
	const [otpCode, setOTPCode] = useState('')
  const [authPhone, setAuthPhone] = useState('')
  const [error, setError] = useState('')

  const handleLogin = async () => {
		try {
			setError('')
			setOTPCode('')
			if (!isPhoneNumber(phone)) {
				setError(t('invalidPhone'))
				return
			}

			// TODO: trigger reverse OTP modal
			const {otp, authPhone: _authPhone} = await triggerLoginByPhone(phone)
			setOTPCode(otp)
      console.log(otp)
			setAuthPhone(_authPhone)
      console.log(_authPhone)

			setShowQRModal(true)


		} catch (error) {

			console.log(error)
		}
	}

  useEffect(() => {
    if (token) {
      setShowQRModal(false)
      onSuccess()
    }
  }, [token, onSuccess])

  if (isLoggedIn) return null

  if (showQRModal) {
    return (
      <QRModal
        code={otpCode}
        phoneNumber={authPhone}
        senderNumber={phone}
        isOpen={showQRModal}
        onClose={() => setShowQRModal(false)}
      />
    )
  }

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md rounded-2xl transform overflow-hidden bg-[#F5F5F5] p-[26px] justify-center items-center shadow-xl transition-all">
                <div className="flex justify-end">
									<button onClick={onClose}>
										<span className="text-[24px] leading-[30px]">
											<Icon
												name="close"
											/>
										</span>
									</button>
								</div>
								<div className="flex justify-center pb-[17px]">
									<img
										src={VERIFY_ICON}
										className="w-[90px] h-[90px]"
										alt={t('verifyDescription')}
									/>
								</div>
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  {t('loginTitle')}
                </Dialog.Title>
                <div className="mt-6 w-[270px] mx-auto
                ">
                  <Input
                    placeholder={t("enterPhone")}
                    value={phone}
                    onChange={setPhone}
                    prefix={() => {
                      return (
                        <img src={VietNamIcon} alt="" className="w-5"/>
                      )
                    }}
                    className="px-[24px] !bg-[#EFEFEF]"
                    inputClassName="w-full"
                  />
                </div>
                {error && (
                  <div className="flex flex-row pt-[4px] justify-center">
                    <span className="text-[14px] text-[#E60016]">{error}</span>
                  </div>
                )}

                <div className="mt-16">
                  <Button className="w-[232px]" onClick={handleLogin}>
                    Login
                  </Button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}