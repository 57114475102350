import { ReactNode } from "react";

interface InputProps {
	value?: any;
	placeholder?: string;
	onChange?: (newValue: any) => void;
	className?: string;
	inputClassName?: string;
	prefix?: () => ReactNode;
	disabled?: boolean
}

export default function Input({value, onChange, placeholder, className = '', inputClassName = '', prefix, disabled = false}: InputProps) {
	return (
		<div className={`bg-[#EFEFEF] rounded-[8px] p-[8px] flex items-center ${className}`}>
			
			{prefix && prefix()}
			
			<input
				className={`bg-transparent focus-visible:outline-none text-center text-[16px] font-bold ${inputClassName}`}
				value={value}
				onChange={(e) => {
					onChange && onChange(e.target.value)
				}}
				placeholder={placeholder}
				disabled={disabled}
			/>
		</div>
	)
}