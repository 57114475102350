import Button from "../../components/Button";
import Header from "../../components/Header";
import {Link} from "react-router-dom";

export default function HomePage() {
	return (
		<>
			<Header className="fixed top-0"/>
			<div className="flex flex-col items-center">
				<img src="/images/Home-background.png" alt="" className={"w-full h-auto mb-3"} />
				<h1 className={"uppercase text-[25.6px] font-paytone mb-6 mt-[24px] leading-[125%]"}>Tiệm cà phê sữa đá</h1>
				<div className={"text-left px-4 font-[400] mb-12 leading-[125%]"}>
					<p>
						Tiệm Cà Phê Sữa Đá là dự án mô hình các nhân vật
						<b> Én, Mỡ Mỡ, Vàng Vàng, Mèo Mốc, Vàng Xám, Pikalong</b>.
					</p>
					<br />
					<p>
						Không dừng lại ở đó, bạn còn có thể sưu tập các phiên bản số đặc biệt
						của các “ly cà phê sữa”
						cực dễ thương này.
					</p>
				</div>
				<Link to={"/enter"}>
					<Button className={"w-[300px] mb-[48px]"}>Vào tiệm</Button>
				</Link>
			</div>
		</>
	)
}