import { ReactNode } from "react";
import Loader from "../Loader";

interface ButtonProps {
	disabled?: boolean;
	children?: ReactNode;
	className?: string;
	onClick?: () => void;
	loading?: boolean
}

export default function Button({disabled = false, children, className = '', onClick, loading}: ButtonProps) {
  return (
		<button
			className={`bg-[#E60016] p-[10px] disabled:bg-[#BFBFBF] text-[#FFFFFF] font-bold rounded-[20px] font-[14px] leading-[17.5px] ${className}`}
			disabled={disabled}
			onClick={onClick}
		>
			{loading ? <Loader size={16} /> : children}
		</button>
	)
}