import { useQuery } from "@tanstack/react-query";
import { getItemDetail } from "../services/items";
import { getCollectionDetail } from "../services/collections";
import { ItemMetaData } from "../types/items";

export function useDetailFromID(id: string) {
  // TODO: fetch detail from id
  const {data, isLoading} = useQuery<ItemMetaData>({
    queryKey: ['itemDetail', id],
    queryFn: async () => {
      try {
        const rs = await getItemDetail(id)
        
        const data = rs.data || {}
        const rarityCode = data.metadata.rarity
        const {data: collectionDetail} = await getCollectionDetail(data.collection_id)

        const rarityConfigs = collectionDetail.configs.filter((item: any) => item.config_code === rarityCode)

        return {
          collectionMetadata: {
            id: data.collection_id,
            name: collectionDetail.name,
            total: rarityConfigs[0].quantity,
            code: collectionDetail.code
          },
          nftMetadata: {
            id: data.id,
            avatar: data.image_url,
            owner: data.user_phone,
            rarity: Math.round(rarityConfigs[0].rate * 100).toString(),
            rarityCode
          }
        } as ItemMetaData
      } catch (error) {
        console.log("error useDetailFromID", error)
        return {} as ItemMetaData
      }
    },
    initialData: {} as ItemMetaData
  })

  return {
    data,
    isLoading
  }
}