import { useTranslation } from "react-i18next"
import Logo from '../../assets/logo.png'
export default function UnsupportedDevices() {
	const {t} = useTranslation()
	return (
		<div className="flex flex-col items-center justify-center h-[100dvh]">
			<img src={Logo} alt="" className={"w-[250px] mb-10"}/>
			<h1 className="text-3xl font-bold underline text-center">{t('unsupportedDevice')}</h1>
		</div>
	)
}