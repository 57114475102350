import { useCallback, useEffect, useMemo, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import Header from "../../components/Header"
import Loader from "../../components/Loader"
import { unpack } from "../../services/collections"

export default function Unpack() {
  const navigate = useNavigate()
  const {search} = useLocation()
	const code = useMemo(() => {
		const paramStringArr = search.replace("?", "").split("&")

		const item = paramStringArr.filter((i) => i.includes("code="))
		if (item.length === 0) return ''
		
		return item[0].replace("code=", "")
	}, [search])

  const [unpacking, setUnpacking] = useState(false)
  const [err, setErr] = useState('')

  const handleUnpack = useCallback(async () => {
    try {
      setErr('')
      const rs = await unpack(code)

      if (rs.error) {
        throw new Error(rs.error.error_msg)
      }

      const itemCode: string = rs.data?.code
      if (itemCode && itemCode.length > 0) {
        navigate(`/unbox?code=${itemCode}`)
      }
    } catch (error) {
      console.log(error)
      setErr((error as Error).message)
    }
  }, [code, navigate])

  useEffect(() => {
    if (unpacking) return
    handleUnpack()
    setUnpacking(true)
  }, [unpacking, handleUnpack])

  return (
    <>
      <Header />
      <div className="flex justify-center items-center mt-[128px]">
        {err ? (
          <div className="text-center text-black gap-4 flex flex-col">
            <div className="text-[24px] font-[500]">Có lỗi xảy ra, vui lòng thử lại sau</div>
            <div className="italic">{err}</div>
          </div>
        ) : (
          <Loader className="!border-black !border-b-transparent" size={48} />
        )}
      </div>
    </>
  )
}