import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useEffect } from "react";
import { useTranslation } from "react-i18next";
import VERIFY_ICON  from '../../assets/verify-phone.png'
import Button from "../../components/Button";
import Icon from "../../components/Icon";
import useAuth from "../../hooks/useAuth";
import ReactGA from "react-ga4";
interface QRModalProps {
	code: string;
	phoneNumber: string;
	senderNumber: string;
	isOpen: boolean;
	onClose: () => void;
}

export default function QRModal({code, phoneNumber, isOpen, onClose, senderNumber}: QRModalProps) {
	const {t} = useTranslation()
	const {sessionId, fetchSessionStatus} = useAuth()
	useEffect(() => {
		(async () => {
			const interval = setInterval(fetchSessionStatus, 5000)
			return () => {
				clearInterval(interval)
			}
		})()
	}, [sessionId, fetchSessionStatus])

  return (
		<Transition appear show={isOpen} as={Fragment}>
			<Dialog as="div" className="relative z-10" onClose={onClose}>
				<Transition.Child
					as={Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="fixed inset-0 bg-black/75" />
				</Transition.Child>

				<div className="fixed inset-0 overflow-y-auto">
					<div className="flex min-h-full items-center justify-center p-4 text-center">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 scale-95"
							enterTo="opacity-100 scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 scale-100"
							leaveTo="opacity-0 scale-95"
						>
							<Dialog.Panel className="w-full max-w-md rounded-2xl transform overflow-hidden bg-[#F5F5F5] p-[26px] justify-center items-center shadow-xl transition-all">
								<div className="flex justify-end">
									<button onClick={onClose}>
										<span className="text-[24px] leading-[30px]">
											<Icon
												name="close"
											/>
										</span>
									</button>
								</div>
								<div className="flex justify-center pb-[17px]">
									<img
										src={VERIFY_ICON}
										className="w-[90px] h-[90px]"
										alt={t('verifyDescription')}
									/>
								</div>
								<Dialog.Title
									as="h3"
									className="text-[25px] font-paytone font-medium leading-6 text-gray-900"
								>
									{t('verifyPhoneNumber')}
								</Dialog.Title>
								<div className="mt-2 p-[24px]">
									{/* TODO: Add tab to select Android or iOS */}
									{/* <QRCode
										size={128}
										style={{ height: "auto", maxWidth: "100%", width: "100%" }}
										value={content}
										viewBox={`0 0 128 128`}
									/> */}
									<span className="text-[15px] leading-[18.75px]">
										{t('verifyDescription')}
									</span>
								</div>

								<div className="mt-4 mb-[32px] flex justify-between gap-4">
									<Button
										className="w-[248px] bg-[#FFF]"
										onClick={() =>{
											ReactGA.event('send-otp',{device_type:'android',phone_number:senderNumber})
											window.open(`sms:${phoneNumber}?body=${code}`)
										}}
									>
										<div className="flex gap-1 items-center justify-center">
											<Icon
												name="android"
												color={"#000"}
											/>
											<span className="text-[#000]">{t('Android')}</span>
										</div>
									</Button>
									<Button className="w-[248px] bg-[#FFF]" onClick={() =>
									{
										ReactGA.event('send-otp',{device_type:'ios',phone_number:senderNumber})
										window.open(`sms:${phoneNumber}&body=${code}`)
									}
									}>
										<div className="flex gap-1 items-center justify-center">
											<Icon
												name="apple"
												color={"#000"}
											/>
											<span className="text-[#000]">{t('iOS')}</span>
										</div>
									</Button>
								</div>

								<div className="mt-2 mb-[32px]">
									<span className="text-[15px] leading-[18.75px]">
										{t('waitForVerification')}
									</span>
								</div>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition>
	)
}