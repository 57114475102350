import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import Icon from "../Icon";
import { useTranslation } from "react-i18next";

export default function InstructionModal({onClose, isOpen}: {
  isOpen: boolean;
  onClose: () => void;
}) {
  const {t} = useTranslation()

  return (
    <Transition appear show={isOpen} as={Fragment}>
			<Dialog as="div" className="relative z-10" onClose={onClose}>
				<Transition.Child
					as={Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="fixed inset-0 bg-black/75" />
				</Transition.Child>

				<div className="fixed inset-0 overflow-y-auto">
					<div className="flex min-h-full items-center justify-center p-4 text-center">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 scale-95"
							enterTo="opacity-100 scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 scale-100"
							leaveTo="opacity-0 scale-95"
						>
							<Dialog.Panel className="w-full rounded-2xl max-w-md transform overflow-hidden shadow-xl transition-all">
              <div className="bg-[#FFF]">
                <div className="bg-[#F5F5F5] p-[26px] justify-center items-center ">
                  <div className="flex justify-end">
                    <button onClick={onClose}>
                      <span className="text-[24px] leading-[30px]">
                        <Icon
                          name="close"
                        />
                      </span>
                    </button>
                  </div>
                  <Dialog.Title
                    as="h3"
                    className="text-[20px] font-paytone font-medium leading-6 text-gray-900 mt-4 mb-[48px]"
                  >
                    {t('instructionTitle')}
                  </Dialog.Title>
                  <Icon
                    name="sts-instruction"
                    width={51}
                    height={51}
                  />
                  <div className="mt-2 text-[16px] leading-[20px] text-left">
                    <span className="text-[15px] leading-[18.75px]">
                      {t('instructionSubTitle')}
                    </span>
                  </div>
                </div>
								<div className="py-[24px] px-[26px] text-left flex flex-col gap-[24px]">
                  <div>
                    <h4 className="text-[#595959]">Bước 1:</h4>
                    <span className="font-bold">Nhấn nút <span className="text-[#E60016] underline font-bold">Order</span></span>
                  </div>
                  <div>
                    <h4 className="text-[#595959]">Bước 2:</h4>
                    <span className="font-bold">Chọn hệ điều hành tương ứng với điện thoại của bạn:</span>
                    <ul className="list-disc list-inside">
                      <li>Điện thoại là Apple hãy chọn <span className="text-[#E60016] underline font-bold">iOS</span></li>
                      <li>Nếu bạn sử dụng thiết bị khác, hãy chọn <span className="text-[#E60016] underline font-bold">Android</span></li>
                    </ul>
                  </div>
                  <div>
                    <h4 className="text-[#595959]">Bước 3:</h4>
                    <span className="font-bold">Bạn hãy <span className="text-[#E60016] underline font-bold">Gửi</span> tin nhắn mà chúng tớ đã nhập sẵn cho bạn để Order ly cà phê nha</span>
                  </div>
                </div>
              </div>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition>
  )
}