import { REFRESH_TOKEN_PATH, SESSION_STATUS_PATH, START_LOGIN_PATH } from "../config/path";

export const getSessionStatus = async (sessionID: string, phoneNumber: string) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_ENDPOINT}${SESSION_STATUS_PATH}?phone=${phoneNumber}&session=${sessionID.replace("0x", "")}`
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    // myHeaders.append("Authorization", `Bearer ${token}`)

    const requestOptions: Record<string, any> = {
      method: 'GET',
      headers: myHeaders,
    };

    const rs = await fetch(url, requestOptions)
    return rs.json() 
  } catch (error) {
    console.log('getSessionStatus error', error)
    return 
  }
}

export const triggerLogin = async (sessionID: string, phoneNumber: string) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_ENDPOINT}${START_LOGIN_PATH}`
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    // myHeaders.append("Authorization", `Bearer ${token}`)
    
    const raw = JSON.stringify({
      "phone_number": phoneNumber,
      "session_id": sessionID.replace("0x", "")
    })

    const requestOptions: Record<string, any> = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    const rs = await fetch(url, requestOptions)
    return rs.json() 
  } catch (error) {
    console.log('unbox error', error)
    return 
  }
}

export const fetchNewToken = async (refreshToken: string) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_ENDPOINT}${REFRESH_TOKEN_PATH}`
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${refreshToken}`)

    const requestOptions: Record<string, any> = {
      method: 'POST',
      headers: myHeaders,
      // body: raw,
      redirect: 'follow'
    };

    const rs = await fetch(url, requestOptions)
    return rs.json() 
  } catch (error) {
    console.log('unbox error', error)
    return 
  }
}