// import { useTranslation } from "react-i18next";
import { CollectionMetaData } from "../../types/collections";

export default function ItemCard({item}: {item: CollectionMetaData}) {
	// const {t} = useTranslation()

  return (
		<div className="flex flex-col w-full aspect-square bg-[#F1EEEB] relative items-center justify-center p-[6px]">
			{/* <div className="flex w-full justify-end">
				<div className="flex justify-center bg-[#000] p-[6px]">
					<span className="text-[#FFF] font-paytone leading-[20px] text-[16px] text-center uppercase">
						{t('digitalVersion')}
					</span>
				</div>
			</div> */}
			{item.code && (
				<img
					src={`/images/unknown_${item.code}.webp`}
					className="z-10 relative w-[244px] h-[304px] mt-[24px]"
					alt={`${item.name || ''}`}
				/>
			)}
		</div>
	)
}