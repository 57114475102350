import LOGO from '../../assets/logo.png'
import { useState } from 'react'
import { Disclosure, Transition } from '@headlessui/react'
import { useTranslation } from 'react-i18next'
import { usePreferenceStore } from '../../state/preference'
import { Link } from 'react-router-dom'
import Divider from '../Divider'
import Icon from '../Icon'
import useAuth from '../../hooks/useAuth'
import Button from '../Button'
import AuthModal from '../AuthModal'

export default function Header({className}:{className?:string}) {
	const {t, i18n} = useTranslation()
	const {setLanguage} = usePreferenceStore()
	const { isLoggedIn, phoneNumber, logout } = useAuth()
	const [showMenu, setShowMenu] = useState(false)
	const [showAuthModal, setShowAuthModal] = useState(false)

	const languageKeys = Object.keys(i18n.services.resourceStore.data)

	if (showAuthModal) {
		return (
			<AuthModal
				isOpen={showAuthModal}
				onClose={() => setShowAuthModal(false)}
				onSuccess={() => {
					setShowAuthModal(false)
				}}
			/>
		)
	}

	return (
		<>
			<div className={`flex justify-between items-center px-[16px] py-[6px] h-[54px] w-full backdrop-blur-[4px] ${className}`}>
				<Link to="/">
					<img
						src={LOGO}
						alt="SUTASO"
						className='w-[74px] h-auto'
					/>
				</Link>
				<button onClick={() => setShowMenu(true)}>
					<Icon
						name='menu'
						width={24}
						height={24}
					/>
				</button>
			</div>
			<Transition
				show={showMenu}
				enter="transition-all duration-25"
        enterFrom="-right-[1000px]"
        enterTo="right-0"
        leave="transition-all duration-150"
        leaveFrom="right-0"
        leaveTo="-right-[1000px]"
				className='fixed top-0 bottom-0 backdrop-blur-[15px] bg-[#D0D0D0]/[0.6] w-full z-20 px-[16px]'
			>
				<div className="flex justify-between items-center px-[16px] py-[6px] h-[54px]">
					<img
						src={LOGO}
						alt="SUTASO"
						className='w-[74px] h-auto'
					/>
					<button onClick={() => setShowMenu(false)}>
						<Icon
							name='close'
							width={24}
							height={24}
						/>
					</button>
				</div>
				{isLoggedIn ? (
					<Link to={`/profile/${phoneNumber}`} className="flex justify-between items-center px-[16px] py-[6px] h-[54px]">
						<span className='font-bold text-[16px] leading-[20px] uppercase text-[#000]'>{t('profile')}</span>
						<Icon
							name='chevron-right'
							width={16}
							height={16}
						/>
					</Link>
				) : (
					<button
						className="flex justify-between items-center px-[16px] py-[6px] h-[54px] w-full"
						onClick={() => {
							setShowMenu(false)
							setShowAuthModal(true)
						}}
					>
						<span className='font-bold text-[16px] leading-[20px] uppercase text-[#000]'>{t('login')}</span>
						<Icon
							name='chevron-right'
							width={16}
							height={16}
						/>
					</button>
				)}
				
				{/* <Link to="/send-gift" className="flex justify-between items-center px-[16px] py-[6px] h-[54px]">
					<span className='font-bold text-[16px] leading-[20px] uppercase text-[#000]'>{t('sendGift')}</span>
					<Icon
						name='chevron-right'
						width={16}
						height={16}
					/>
				</Link> */}
				<Divider className='mx-[16px]' />
				<Disclosure>
					{({ open }) => (
						/* Use the `open` state to conditionally change the direction of an icon. */
						<>
							<Disclosure.Button className="flex justify-between items-center px-[16px] py-[6px] h-[54px] w-full">
								<span className='font-bold text-[16px] leading-[20px] uppercase text-[#000]'>{t('language')}</span>
								{open ? (
									<Icon
										name='chevron-down'
										width={16}
										height={16}
									/>
								) : (
									<Icon
										name='chevron-right'
										width={16}
										height={16}
									/>
								)}
							</Disclosure.Button>
							<Disclosure.Panel>
								{languageKeys.map((lang: string) => {
									return (
										<div key={`language-${lang}`} className="flex justify-between items-center px-[16px] w-full pb-[12px]"> 
											<button
												className='text-[16px] text-[#454545] leading-[20px] uppercase font-bold'
												onClick={() => {
													i18n.changeLanguage(lang)
                  				setLanguage(lang)
												}}
											>
												{t(lang)}
											</button>
										</div>
									)
								})}
							</Disclosure.Panel>
						</>
					)}
				</Disclosure>
				{isLoggedIn && (
					<div className='mx-[16px] mt-[12px]'>
						<Button
							className='w-full'
							onClick={() => {
								logout()
								setShowMenu(false)
							}}
						>
							{t('logout')}
						</Button>
					</div>
				)}
			</Transition>
		</>
	)
}