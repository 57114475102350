import { create } from 'zustand'
import { persist } from 'zustand/middleware'

interface PreferenceState {
  darkMode: boolean;
  toggleDarkMode: () => void
  language: string;
  setLanguage: (langKey: string) => void
}

export const usePreferenceStore = create<PreferenceState>()(
  persist<PreferenceState>(
    (set, get) => ({
      darkMode: false,
      toggleDarkMode: () => set({ darkMode: !(get().darkMode) }),
      language: "en",
      setLanguage: (langKey: string) => set({ language: langKey })
    }),
    {
      name: "preference-storage", // unique name
    }
  )
)