import React, {useState} from "react";
import {isPhoneNumber} from "../../../utils/string";

interface ContactFormData {
  name: string;
  address: string;
  phoneNumber: string;
  email: string;
}

export default function FormModal({quantity, price, size, onClose, onAddInfo}: {
  quantity: number,
  price: number,
  size: 'single' | 'full'
  onClose: () => void,
  onAddInfo: () => void
}) {
  const [formData, setFormData] = useState<ContactFormData>({
    name: '',
    address: '',
    phoneNumber: '',
    email: '',
  });
  const [isInvalidPhone, setIsInvalidPhone] = useState<boolean>(false);


  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onAddInfo()
    console.log('Form data:', formData);
    if (!isPhoneNumber(formData.phoneNumber)) {
      setIsInvalidPhone(true)
      return
    }
    try {
      const url = 'https://music-api.sutaso.com/api/v1/shipping-beanies'
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      // myHeaders.append("Authorization", `Bearer ${token}`)
      let number
      if (size === 'single') {
        number = quantity
      }
      if (size === 'full') {
        number = quantity * 6
      }
      const raw = JSON.stringify({
        "name": formData.name,
        "phone_number": formData.phoneNumber,
        "email": formData.email,
        "address": formData.address,
        "number": number,
        "type": "Animal Friends",
        "size": size === 'single' ? 'single' : 'full box'
      })

      const requestOptions: Record<string, any> = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      await fetch(url, requestOptions)
      alert('Đặt hàng thành công')
      onClose()
      return
      // return rs.json()
    } catch (error) {
      console.log('order error', error)
      alert('Đặt hàng thất bại. Vui lòng thử lại sau')
      onClose()
      return
    }

  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const {name, value} = event.target;
    if (name === 'phoneNumber') {
      setIsInvalidPhone(false)
    }
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]:
      value,
    }));
  };

  return (
    <div
      className='fixed top-0 left-0 w-full h-screen flex items-center justify-center px-4'>
      <div className='absolute top-0 left-0 w-full h-full bg-black/[0.6] z-[0]'
           onClick={() => onClose()}></div>
      <form onSubmit={
        handleSubmit
      }
            className='relative w-full md:w-[500px] bg-[#F5F5F5] rounded-[18px] px-4 pt-10 pb-10 z-10'>
        <div
          className='absolute top-1 right-2 h-[32px] aspect-square flex items-center justify-center text-[32px] cursor-pointer'
          onClick={() => onClose()}>×
        </div>
        <h2 className='text-center font-paytone text-lg mb-10'>Thông tin giao
          hàng</h2>
        <div className='bg-white relative mb-6 rounded-[8px]'>
          <label htmlFor="name"
                 className={`opacity-0 absolute text-[13px] text-black/[0.8] top-0 left-2 transition-all ${formData.name && 'opacity-100'}`}>Họ
            và tên</label>
          <input type="text" name="name" value={formData.name}
                 placeholder='Họ và tên'
                 className={`w-full h-full bg-transparent rounded-[8px] outline focus:outline-black/[0.5] outline-transparent outline-2 px-2 transition-all ${formData.name ? 'pt-[19px] pb-[5px]' : "py-3"}`}
                 onChange={handleChange}
                 onBlur={()=>{setFormData((prevFormData) => ({...prevFormData, name: formData.name.trim()}))}}
                 required/>
        </div>
        <div
          className={`bg-white relative ${isInvalidPhone ? 'mb-2' : 'mb-6'} rounded-[8px]`}>
          <label htmlFor="phoneNumber"
                 className={`opacity-0 absolute text-[13px] text-black/[0.8] top-0 left-2 transition-all ${formData.phoneNumber && 'opacity-100'}`}>Số
            điện thoại</label>
          <input type="tel" name="phoneNumber" value={formData.phoneNumber}
                 placeholder='Số điện thoại'
                 className={`w-full h-full bg-transparent rounded-[8px] outline focus:outline-black/[0.5] outline-transparent outline-2 px-2 transition-all ${formData.phoneNumber ? 'pt-[19px] pb-[5px]' : "py-3"} ${isInvalidPhone && '!outline-[#E60018]/[0.5]'}`}
                 onChange={handleChange} required/>
        </div>
        {isInvalidPhone && (<div className='text-red-600 mb-6 text-sm'>
          Số điện thoại không hợp lệ</div>)}
        <div className='bg-white relative mb-6 rounded-[8px]'>
          <label htmlFor="address"
                 className={`opacity-0 absolute text-[13px] text-black/[0.8] top-0 left-2 transition-all ${formData.address && 'opacity-100'}`}>Địa
            chỉ
          </label>
          <input type="text" name="address" value={formData.address}
                 placeholder='Địa chỉ'
                 className={`w-full h-full bg-transparent rounded-[8px] outline focus:outline-black/[0.5] outline-transparent outline-2 px-2 transition-all ${formData.address ? 'pt-[19px] pb-[5px]' : "py-3"}`}
                 onChange={handleChange}
                 onBlur={()=>{setFormData((prevFormData) => ({...prevFormData, address: formData.address.trim()}))}}
                 required/>
        </div>
        <div className='bg-white relative mb-6 rounded-[8px]'>
          <label htmlFor="email"
                 className={`opacity-0 absolute text-[13px] text-black/[0.8] top-0 left-2 transition-all ${formData.email && 'opacity-100'}`}>
            Email
          </label>
          <input type="email" name="email" value={formData.email}
                 placeholder='Email'
                 className={`w-full h-full bg-transparent rounded-[8px] outline focus:outline-black/[0.5] outline-transparent outline-2 px-2 transition-all ${formData.email ? 'pt-[19px] pb-[5px]' : "py-3"}`}
                 onChange={handleChange}
                 required/>
        </div>
        <div className='mb-3 font-bold'>Số
          lượng: {quantity} {size === 'single' ? 'Hộp Đơn' : 'Full Bộ'}</div>
        <hr/>
        <div className='mt-3 flex w-full justify-between font-bold'>Tổng
          cộng: <span
            className='text-[#E60018]'>{(price * quantity).toLocaleString('en-US')} đ</span>
        </div>
        {/*<label>*/}
        {/*  Address:*/}
        {/*  <textarea name="address" value={formData.address}*/}
        {/*            onChange={handleChange} required/>*/}
        {/*</label>*/}
        {/*<br*/}
        {/*/>*/}
        {/*<label>*/}
        {/*  Phone Number:*/}
        {/*  <input type="tel" name="phoneNumber" value={formData.phoneNumber}*/}
        {/*         onChange={handleChange} required/>*/}
        {/*</label>*/}
        {/*<br/>*/}
        {/*<label>*/}

        {/*  Email:*/}
        {/*  <input type="email" name="email" value={formData.email}*/}
        {/*         onChange={handleChange} required/>*/}
        {/*</label>*/}
        <button type="submit"
                className='w-full bg-[#E60018] text-white rounded-[20px] mt-6 font-bold uppercase h-[44px] active:opacity-80 transition-all'>Đặt
          hàng
        </button>
      </form>
    </div>
  );
}
