import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../components/Button";
import Icon from "../../components/Icon";
import Input from "../../components/Input";
import { useDetailFromID } from "../../hooks/useDetailFromID";
import { useState } from "react";
import { addZero, isPhoneNumber } from "../../utils/string";
import Loader from "../../components/Loader";
import SendSuccess from "./SendSuccess";
import SendFail from "./SendFail";
import { transferItem } from "../../services/items";
import useAuth from "../../hooks/useAuth";
import VietNamIcon from "../../assets/vietnam.svg"

export default function SendGiftPage() {
  const { itemId } = useParams();
  const { t } = useTranslation()
  const navigate = useNavigate();

  const {data: item, isLoading} = useDetailFromID(itemId || "")
  const {token} = useAuth()

  const [phone, setPhone] = useState('')
  const [error, setError] = useState('')
  const [sendStatus, setSendStatus] = useState('')

  const handleConfirm = async () => {
    try {
      if (!itemId) return
			setError('')
			// setOTPCode('')
			setSendStatus('sending')
			if (!isPhoneNumber(addZero(phone))) {
				setSendStatus('')
				setError(t('invalidPhone'))
				return
			}
			// TODO: call send gift API
      const rs = await transferItem(itemId, phone, token)
			console.log(rs)
      if (rs) {
        setSendStatus('success')
      }
      else {
        setSendStatus('fail')
      }
		} catch (error) {
			setSendStatus('fail')
			console.log(error)
		}
  }

  if (isLoading) {
		return (
			<div className="h-[100vh] w-[100vw]">
				<Loader size={48} />
			</div>
		)
	}

  if (sendStatus === 'success') {
    return <SendSuccess item={item} phoneNumber={phone} />
  }

  if (sendStatus === 'fail') {
    return <SendFail item={item} phoneNumber={phone} onRetry={() => setSendStatus('')} />
  }

  return (
    <div className="p-[16px] flex h-[100vh] flex-col">
      <button onClick={() => navigate(-1)}>
        <Icon
          name="chevron-left"
        />
      </button>
      <div className="flex-1 flex flex-col items-center mb-[24px]">
        <h3 className="font-paytone text-[32px] leading-[40px] uppercase mb-[8px] mt-[48px]">
          {t('sendGift')}
        </h3>
        <div className="w-[248px] text-center text-[14px] leading-[17.5px] mb-[37px]">
          {t('sendGiftDescription')}
        </div>
        <Input
          placeholder={t("enterPhone")}
          prefix={() => {
            return (
              <img src={VietNamIcon} alt="" className="w-5 h-5 mr-1"/>
            )
          }}
          className="px-[24px]"
          value={phone}
          onChange={setPhone}
        />
        {error && (
          <div className="flex flex-row pt-[4px] justify-center">
            <span className="text-[14px] text-[#E60016]">{error}</span>
          </div>
        )}
      </div>
      <div className="flex flex-col items-center">
        <div className="mb-[16px] bg-[#F5F5F5] rounded-[12px] p-[12px]">
          <div className="text-[#454545] text-[12px] font-bold leading-[16.2px] flex items-center gap-2 mb-[10px]">
            <Icon
              name="warning"
            />
            {t('notice')}:
          </div>
          <div className="text-[12px] leading-[15px] text-[#454545]">
            {t('confirmSendWarning')}
          </div>
        </div>
        <Button className="w-full" onClick={handleConfirm} loading={sendStatus === 'sending'}>
          {t('confirmSend')}
        </Button>
      </div>
    </div>
  )
}