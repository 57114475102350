import { useQuery } from "@tanstack/react-query";
import { getCollectionDetailByCode } from "../services/collections";
import { CollectionMetaData } from "../types/collections";
import { ItemMetaData } from "../types/items";
import { getItemDetailFromCode } from "../services/items";

export function useCodeDetail(code: string) {
  const {data, isLoading} = useQuery({
    queryKey: ['collectionDetailFromCode', code],
    queryFn: async () => {
      try {
        const {data: collectionDetail} = await getCollectionDetailByCode(code)
        return {
          name: collectionDetail.name,
          avatar: collectionDetail.avatar,
          total: collectionDetail.configs.reduce((pre: number, cur: any) => {
            return pre + cur.quantity
          }, 0),
          code: collectionDetail.code
        } as CollectionMetaData
      } catch (error) {
        console.log("error collectionDetailFromCode", error)
        return {} as CollectionMetaData
      }
    },
    initialData: {} as CollectionMetaData
  })

  // TODO: fetch nft detail from code if claimed
  const {data: itemDetail, refetch} = useQuery({
    queryKey: ['itemDetailFromCode', code],
    queryFn: async () => {
      try {
        const {data} = await getItemDetailFromCode(code)
        if (!data) return {} as ItemMetaData
        return {
          collectionID: data.collection_id.toString(),
          nftID: data.id.toString(),
          nftMetadata: {
            id: data.id,
            avatar: data.image_url,
            rarity: data.metadata.rarity,
            owner: data.user_phone
          }
        } as ItemMetaData
      } catch (error) {
        console.log("error collectionDetailFromCode", error)
        return {} as ItemMetaData
      }
    },
    initialData: {} as ItemMetaData
  })

  return {
    data,
    itemDetail,
    isLoading,
    refetch
  }
}