export const UNBOX_PATH = '/api/v1/items/unbox'
export const ITEM_DETAIL_PATH = '/api/v1/items/'
export const ITEM_DETAIL_FROM_CODE_PATH = '/api/v1/items/code/'
export const COLLECTION_DETAIL_PATH = '/api/v1/collections/'
export const COLLECTION_DETAIL_BY_CODE_PATH = '/api/v1/collections/code/'
export const PORTFOLIO_PATH = '/api/v1/items/'
export const SESSION_STATUS_PATH = '/auth/status'
export const START_LOGIN_PATH = "/auth/kyc"
export const REFRESH_TOKEN_PATH = "/auth/refresh"
export const TRANSFER_ITEM_PATH = "/api/v1/transfers/"
export const UNPACK_PATH = "/api/v1/packs/unpack"