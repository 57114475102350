import { useNavigate } from "react-router-dom";
import Icon from "../../components/Icon";
import { Trans, useTranslation } from "react-i18next";
import { addZero } from "../../utils/string";
import Button from "../../components/Button";
import useAuth from "../../hooks/useAuth";
import { ItemMetaData } from "../../types/items";

export default function SendSuccess({item, phoneNumber}: {
  item?: ItemMetaData;
  phoneNumber?: string;
}) {
  const navigate = useNavigate();
  const {t} = useTranslation()
  const {phoneNumber: userPhoneNumber} = useAuth()

  if (!item || !phoneNumber) return null

  return (
    <div className="p-[16px] flex h-[100vh] flex-col">
      <button onClick={() => navigate(-1)}>
        <Icon
          name="chevron-left"
        />
      </button>
      <div className="flex-1 flex flex-col items-center pt-[78px]">
        <Icon
          name="send-success"
          width={84}
          height={84}
        />
        <h3 className="text-[32px] font-paytone leading-[40px] uppercase">{t('sendSuccess')}</h3>
        <div className="bg-[#E60016] px-[6px] py-[4px] text-[#FFFFFF] font-paytone leading-[22.5px] uppercase text-[18px] mb-[32px]">
          {item.collectionMetadata?.name}
        </div>
        <span className="text-center pb-[48px] w-[248px] text-[#454545] leading-[17.5px] text-[14px]">
          <Trans
            i18nKey={"sendSuccessDescription"}
            values={{
              characterName: item.collectionMetadata?.name,
              phoneNumber: addZero(phoneNumber)
            }}
          >
            Chúc mừng bạn đã gửi thành công Ly Cà Phê Sữa Đá <strong>{item.collectionMetadata?.name}</strong> đến Bàn số <strong>{phoneNumber}</strong>
          </Trans>
        </span>
        <Button className="w-[250px]" onClick={() => navigate(`/profile/${userPhoneNumber}`, {replace: true})}>
          {t('goToProfile')}
        </Button>
      </div>
    </div>
  )
}