import Header from "../../components/Header";
import ProfileItemCard from "../../components/ProfileItemCard";
import {SearchPhoneNumber} from "../../components/SearchPhoneNumber";

export default function EnterPage() {

  return (
    <>
      <Header className={"backdrop-blur-[15px] fixed top-0"}/>
      <SearchPhoneNumber />
      <div className={"px-4 pt-6"}>
        <h2 className={"text-black text-xl font-black leading-[125%]  mb-4"}>Explore ConCafe</h2>
        <div className={"grid grid-cols-2 gap-x-3 gap-y-4 pb-[60px]"}>
          <ProfileItemCard demoItem={{name: "Én", avatar: "/model/c1_lv1.png"}} />
          <ProfileItemCard demoItem={{name: "Mỡ Mỡ", avatar: "/model/c2_lv1.png"}} />
          <ProfileItemCard demoItem={{name: "Mèo Mốc", avatar: "/model/c3_lv1.png"}} />
          <ProfileItemCard demoItem={{name: "Vàng Vàng", avatar: "/model/c4_lv1.png"}} />
          <ProfileItemCard demoItem={{name: "Vàng Xám", avatar: "/model/c5_lv1.png"}} />
          <ProfileItemCard demoItem={{name: "Pikalong", avatar: "/model/c6_lv1.png"}} />
        </div>
      </div>
    </>
  );
};
