import { useCallback, useEffect, useMemo, useRef } from "react"
import {ModelViewerElement} from '@google/model-viewer'
import "@google/model-viewer/dist/model-viewer.min.js"
import Icon from "../Icon"
import AR_HAND_PROMPT from './ar/ar_hand_prompt.png'
import "./style.css"
import { ItemMetaData } from "../../types/items"

const getLevel = (rarityCode: string) => {
  switch (rarityCode) {
    case "common":
      return "lv1"
    case "uncommon":
      return "lv2"
    case "rare":
      return "lv3"
    case "epic":
      return "lv4"
    case "legendary":
      return "lv5"
    default:
      return ""
  }
}

export default function ARView({onClose, item}: {
  onClose: () => void;
  item: ItemMetaData
}) {
  const modelName = useMemo(() => {
    console.log(item)
    return `${item.collectionMetadata?.code}_${getLevel(item.nftMetadata?.rarityCode || "")}`
  }, [item])

  const arRef = useRef<ModelViewerElement>(null)

  const onProgress = useCallback((event: any) => {
    const progressBar = event.target.querySelector('.progress-bar');
    const updatingBar = event.target.querySelector('.update-bar');
    updatingBar.style.width = `${event.detail.totalProgress * 100}%`;
    if (event.detail.totalProgress === 1) {
      progressBar.classList.add('hide');
      event.target.removeEventListener('progress', onProgress);
    } else {
      progressBar.classList.remove('hide');
    }
  }, []);

  useEffect(() => {
    if (!arRef || !arRef.current) return
    arRef.current.addEventListener('progress', onProgress);
  }, [arRef, onProgress])

  return (
    <div>
      <button onClick={onClose} className="absolute top-4 left-4 z-20">
        <Icon
          name="close"
          width={36}
          height={36}
        />
      </button>
      <model-viewer
        ref={(arRef)}
        src={`/model/${modelName}.glb`}
        ios-src={`/model/${modelName}.usdz`}
        // @ts-ignore
        ar
        ar-modes="webxr scene-viewer quick-look"
        camera-controls
        tone-mapping="commerce"
        poster={`/model/${modelName}.png`}
        shadow-intensity="1"
        autoPlay
      >
        <div className="progress-bar hide" slot="progress-bar">
          <div className="update-bar"></div>
        </div>
        <button slot="ar-button" id="ar-button">
          View in your space
        </button>
        <div id="ar-prompt">
          <img src={AR_HAND_PROMPT} alt="Sutaso" />
        </div>
      </model-viewer>  
    </div>
  )
}