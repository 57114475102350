import Header from "../../../components/Header";
import Poster from '../../../assets/halloween.jpg'
import Logo from '../../../assets/logo.png'
import {useMemo, useState} from "react";
import {Disclosure, Transition} from '@headlessui/react'
import FormModal from "./FormModal";
import {useWindowSize} from "usehooks-ts";
import {MOBILE_WIDTH} from "../../../config/constant";
import ReactPixel from 'react-facebook-pixel';

const options = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: true, // enable logs
};
ReactPixel.init('826938569570901', undefined, options);
ReactPixel.pageView()

export default function HalloweenPage() {
  const [quantity, setQuantity] = useState<number>(1)
  const [size, setSize] = useState<'single' | 'full'>('single')
  const [isFormOpen, setIsFormOpen] = useState<boolean>(false)
  const price = useMemo(() => {
    if (size === 'single') {
      return 230000
    } else
      return (230000 * 6)

  }, [size])

  const {width} = useWindowSize()


  return (
    <div
      className='font-sans flex flex-col md:flex-row items-start md:px-10 md:pb-[100px]'>
      {width > MOBILE_WIDTH ? <div
          className='flex items-center justify-center w-full fixed top-0 !backdrop-blur-[15px] py-4'>
          <img src={Logo} alt="" className='w-[140px]'/></div> :
        <Header className="fixed top-0 !backdrop-blur-[15px]"/>}
      <img src={Poster} alt='Jack-o-Beanie'
           className='w-full h-auto md:h-auto md:w-[50vw] pt-[54px] md:pt-[100px]'/>
      <div
        className="flex flex-col justify-center md:justify-start pt-5 md:pt-[100px] px-4 pb-20 w-full">
        <h1
          className='font-paytone uppercase text-[20.8px] break-normal'>Bộ sưu tập Animal Friends
        </h1>
        <h2 className='mt-1 font-bold'>Thương hiệu: SuTaSo</h2>
        <h3
          className='flex items-center space-x-2 w-full mt-1 text-[#DC2128] text-[18px] tracking-wide font-bold'>
          <div>{(price * quantity).toLocaleString('en-US')}đ</div>
          <span className='font-medium text-black text-[90%] line-through'>280,000đ</span>
        </h3>

        <div className='mt-6 mb-2 font-medium'>SIZE</div>
        <div className='flex space-x-2 w-full justify-between'>
          <button
            onClick={() => {
              setSize('single')
            }}
            disabled={size === 'single'}
            className='h-12 flex-1 bg-[#F5F5F5] flex items-center justify-center border-black border-[1px] font-bold text-sm rounded-[8px] opacity-25 disabled:opacity-100 transition-all'>Hộp
            Đơn
          </button>
          <button
            onClick={() => {
              setSize('full')
            }}
            disabled={size === 'full'}
            className='h-12 flex-1 bg-[#F5F5F5] flex items-center justify-center border-black border-[1px] font-bold text-sm rounded-[8px] opacity-25 disabled:opacity-100 transition-all'>Full
            Bộ
            (6 Hộp)
          </button>
        </div>

        <div className='mb-2 font-medium mt-6'>SỐ LƯỢNG</div>
        <div
          className='flex justify-between items-center w-[140px] border-gray-300 border-[1px] px-2 py-1.5 rounded-[8px]'>
          <button
            className='aspect-square h-6 font-bold'
            onClick={() => {
              if (quantity === 1) return
              setQuantity((quantity) => quantity - 1)
            }}>-
          </button>
          <div>{quantity}</div>
          <button
            className='aspect-square h-6 font-bold'
            onClick={() => {
              setQuantity((quantity) => quantity + 1)
            }}>+
          </button>
        </div>
        <button
          className='w-full bg-[#E60018] text-white rounded-[20px] mt-6 font-bold uppercase h-[44px] active:opacity-80 transition-all'
          onClick={() => {
            setIsFormOpen(true)
            ReactPixel.track('AddToCart', {
              contents_id: ['0001'],
              content_type: 'product',
              contents: [{'id':'0001','quantity':quantity}],
              currency: 'VND',
              value: price * quantity
            })
          }}>
          Đặt Hàng
        </button>
        <hr className='mt-6'/>
        <Disclosure defaultOpen={true}>
          {({open}) => (
            <>
              <Disclosure.Button
                className="mt-6 flex w-full justify-between rounded-lg px-4 py-2 text-left text-lg font-medium uppercase">
                <span>Mô tả sản phẩm</span>
              </Disclosure.Button>
              <Disclosure.Panel className="px-4 pb-2 pt-2 text-sm">
                Khám phá thế giới Animal Friends: Bộ sưu tập Hộp mù (Blind box)
                độc đáo!
                <br/>
                Hãy để những người bạn nhỏ đáng yêu này làm sáng bừng ngày của
                bạn! Bộ sưu tập Blindbox Animal Friends mang đến 6 nhân vật dễ
                thương với thiết kế độc đáo, màu sắc pastel mềm mại, và nụ cười
                ngọt ngào. Mỗi hộp là một bất ngờ thú vị!
                <br/><br/>
                Đặc điểm nổi bật: <br/>
                <ul>
                  <li>• 6 nhân vật đáng yêu: thỏ, mèo, hươu và nhiều hơn nữa
                  </li>
                  <li>• Chất liệu cao cấp, an toàn cho trẻ em</li>
                  <li>• Lý tưởng cho sưu tầm và trang trí</li>
                </ul>

                <br/>
                Cho dù bạn là fan cứng của art toys hay đang tìm một món quà độc
                đáo, Animal Friends chắc chắn sẽ mang lại niềm vui bất tận. Đừng
                bỏ lỡ cơ hội sở hữu bộ sưu tập giới hạn này - mua ngay hôm nay
                và để Animal Friends làm tan chảy trái tim bạn!
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
        <Transition
          show={isFormOpen}
          enter="transition-opacity duration-75"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-150"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <FormModal quantity={quantity} price={price} size={size}
                     onClose={() => setIsFormOpen(false)}
                     onAddInfo={() => {
                       ReactPixel.track('AddPaymentInfo')}}
          />
        </Transition>
      </div>
    </div>
  )
}
