import { useParams } from "react-router-dom";
import Header from "../../components/Header";
import ProfileItemCard from "../../components/ProfileItemCard";
import {SearchPhoneNumber} from "../../components/SearchPhoneNumber";
import { hidePhone } from "../../utils/string";
import { usePortfolio } from "../../hooks/usePortfolio";
import { useMemo } from "react";

export default function ProfileCollectionPage() {

  const {phoneNumber} = useParams()

  const {items} = usePortfolio(phoneNumber || "")

  const collection = useMemo(() => {
    return items ? items?.pages.flat() : []
  }, [items])

  return (
    <>
      <Header className="fixed top-0 !backdrop-blur-[15px]" />
      <div
        className={"flex flex-col items-center fixed top-[54px] w-full pt-8 pb-5 backdrop-blur-[15px] "}>
        <div
          className={"uppercase font-paytone text-[15px] text-[#363636] mb-3"}>Bạn
          đang Xem bộ sưu tập
        </div>
        <div
          className={"font-paytone uppercase text-[21.237px] mb-1.5 flex items-center gap-x-2"}>
          <span>Bàn số</span>
          <span className={"px-1.5 bg-[#000] text-[#FFF]"}>{hidePhone(phoneNumber || '')}</span>
        </div>
        <div className={"text-xs text-[#454545]"}>
          Đã sưu tầm được:&nbsp;
          <span className={"text-[#000]"}>
            <b>
              {collection.length}
            </b>
            &nbsp;Ly Cà Phê
          </span>
        </div>
      </div>
      {!!collection.length ?
        <div
          className={"pt-[240px] pb-[40px] grid grid-cols-2 px-4 gap-x-3 gap-y-6"}
        >
          {collection.map((item) => (
            <ProfileItemCard itemID={item.id} key={`item-${item.id}`} />
          ))}
        </div>
        :
        <div className={'pt-[250px] flex flex-col items-center text-sm w-full h-[100dvh]'}>
          <img src="/images/Binoculars.svg" alt="" className={"relative left-[14.3px] mb-4"}/>
          <div className={"font-bold"}>Hãy xem bàn khác nhé!</div>
          <div className={"mb-[92px]"}>Bàn này chưa sưu tầm ly cà phê nào cả!</div>
          <SearchPhoneNumber className={"pt-[50px] rounded-t-[20px] h-full"}/>
        </div>
      }
    </>
  )
}