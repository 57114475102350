import { Link } from "react-router-dom";
import { useDetailFromID } from "../../hooks/useDetailFromID";
import Loader from "../Loader";
import {useMemo} from "react";
const getLevel = (rarityCode: string) => {
  switch (rarityCode) {
    case "common":
      return "lv1"
    case "uncommon":
      return "lv2"
    case "rare":
      return "lv3"
    case "epic":
      return "lv4"
    case "legendary":
      return "lv5"
    default:
      return "lv1"
  }
}
export default function ProfileItemCard({itemID, demoItem}: {
  itemID?: string;
  demoItem?: Record<string, any>
})  {
  const {data, isLoading} = useDetailFromID(itemID || "")

  const modelName = useMemo(() => {
    return `${data.collectionMetadata?.code}_${getLevel(data.nftMetadata?.rarityCode || "")}`
  }, [data])

  if (!itemID) {
    if (!demoItem) return null
    return (
      <div className="w-full flex flex-col items-center">
        <div className="mb-2 w-[166px] h-[166px] bg-[#F1EEEB] flex items-center justify-center">
          <img
            className="w-[94px] h-[128px]"
            src={demoItem.avatar}
            alt={`${demoItem.name}`}
          />
        </div>
        {/* <h3
          className="text-[#454545] text-[10px] font-bold capitalize leading-[125%]">{demoItem.name}
        </h3> */}
        <div
          className="text-black font-bold leading-[125%]">{demoItem.name}
        </div>
      </div>
    )
  }

  if (isLoading) {
		return (
			<div>
				<Loader size={48} />
			</div>
		)
	}

  return (
    <Link to={`/item/${itemID}`}>
      <div className="w-full flex flex-col items-center">
        <div className="mb-2 w-[166px] h-[166px] bg-[#F1EEEB] flex items-center justify-center">
          <img
            className="w-[94px] h-[128px]"
            src={data.collectionMetadata?.code === 'thantich1' ? data.nftMetadata?.avatar : `/model/${modelName}.png`}
            alt={`${data.collectionMetadata?.name} - ${data.nftMetadata?.id}`}
          />
        </div>
        <h3
          className="text-[#454545] text-[10px] font-bold capitalize leading-[125%]">{data.collectionMetadata?.name}
        </h3>
        <div
          className="text-black font-bold leading-[125%]">{data.nftMetadata?.id}
        </div>
      </div>
    </Link>
  );
};
