import { ITEM_DETAIL_FROM_CODE_PATH, ITEM_DETAIL_PATH, UNBOX_PATH, TRANSFER_ITEM_PATH } from "../config/path"

export const unboxItem = async (code: string, token: string) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_ENDPOINT}${UNBOX_PATH}`
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`)
    
    const raw = JSON.stringify({
      code
    })

    const requestOptions: Record<string, any> = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    const rs = await fetch(url, requestOptions)
    return rs.json() 
  } catch (error) {
    console.log('unbox error', error)
    return 
  }
}

export const getItemDetail = async (itemID: string) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_ENDPOINT}${ITEM_DETAIL_PATH}${itemID}`
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    // myHeaders.append("Authorization", `Bearer ${token}`)

    const requestOptions: Record<string, any> = {
      method: 'GET',
      headers: myHeaders,
    };

    const rs = await fetch(url, requestOptions)
    return rs.json() 
  } catch (error) {
    console.log('getItemDetail error', error)
    return 
  }
}

export const getItemDetailFromCode = async (code: string) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_ENDPOINT}${ITEM_DETAIL_FROM_CODE_PATH}${code}`
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    // myHeaders.append("Authorization", `Bearer ${token}`)

    const requestOptions: Record<string, any> = {
      method: 'GET',
      headers: myHeaders,
    };

    const rs = await fetch(url, requestOptions)
    return rs.json() 
  } catch (error) {
    console.log('getItemDetail error', error)
    return 
  }
}

export const transferItem = async (itemID: string, to: string, token: string) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_ENDPOINT}${TRANSFER_ITEM_PATH}`
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`)
    
    const raw = JSON.stringify({
      "item_ids": [Number(itemID)],
      to
    })

    const requestOptions: Record<string, any> = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    const rs = await fetch(url, requestOptions)
    return rs.json() 
  } catch (error) {
    console.log('transferItem error', error)
    return 
  }
}