import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom"
import { useCodeDetail } from "../../hooks/useCodeDetail";
import { useTranslation } from "react-i18next";
import ItemCard from "../../components/ItemCard";
import Button from "../../components/Button";
import Input from "../../components/Input";
import { isPhoneNumber } from "../../utils/string";
import Loader from "../../components/Loader";
import QRModal from "./QRModal";
import Header from "../../components/Header";
import useAuth from "../../hooks/useAuth";
import { unboxItem } from "../../services/items";
import UnboxSuccess from "./UnboxSuccess";
import InstructionModal from "../../components/InstructionModal";
import ReactGA from "react-ga4";

export default function UnboxPage() {

	const {search} = useLocation()
	const code = useMemo(() => {
		const paramStringArr = search.replace("?", "").split("&")

		const item = paramStringArr.filter((i) => i.includes("code="))
		if (item.length === 0) return ''
		
		return item[0].replace("code=", "")
	}, [search])

	const {isLoading, data, itemDetail, refetch} = useCodeDetail(code || "")

	const {t} = useTranslation()
	const {token, phoneNumber, triggerLoginByPhone} = useAuth()

	const [phone, setPhone] = useState(phoneNumber)
	const [authPhone, setAuthPhone] = useState('')
	const [error, setError] = useState('')
	const [loading, setLoading] = useState(false)
	const [showInstructionModal, setShowInstructionModal] = useState(false)
	const [showQRModal, setShowQRModal] = useState(false)
	const [otpCode, setOTPCode] = useState('')
	const [authTriggered, setAuthTriggered] = useState(false)

	const handleClaim = useCallback(async () => {
		if (!token) return
		setAuthTriggered(false)

		if (!code) return;
		setError('')
		try {
			const rs = await unboxItem(code, token)
			console.log(rs)
			await refetch()
			ReactGA.event('unbox_success', { phone_number: phone, nft_id: itemDetail.nftID })
		} catch (error) {
			console.log(error)
			setError('Try again later')
		}
	}, [token, code, refetch, itemDetail, phone])

	const handleLogin = async () => {
		try {
			setError('')
			setOTPCode('')
			setLoading(true)
			if (!isPhoneNumber(phone)) {
				setLoading(false)
				setError(t('invalidPhone'))
				return
			}
			setAuthTriggered(true)
			// TODO: trigger reverse OTP modal
			const {otp, authPhone: _authPhone} = await triggerLoginByPhone(phone)
			setOTPCode(otp)
			console.log(otp)
			setAuthPhone(_authPhone)
			console.log(_authPhone)

			setShowQRModal(true)

			setLoading(false)
		} catch (error) {
			setLoading(false)
			console.log(error)
		}
	}

	useEffect(() => {
		if (token && authTriggered) {
			// setShowQRModal(false)
			handleClaim()
		}
	}, [token, authTriggered, handleClaim])

	useEffect(() => {
		itemDetail.nftID && setShowQRModal(false)
	}, [itemDetail])

	useEffect(() => {
		setPhone(phoneNumber)
	}, [phoneNumber])

	const renderHeader = () => {
		switch (data.code) {
			case 'thantich1':
				return (
					<>
						<h1 className="font-paytone text-left text-[12px]">Bạn đang xem</h1>
						<div className="flex flex-row pb-[22px]">
							<span className="font-paytone text-[24px] pr-[4px] uppercase">Thần tích</span>
						</div>
					</>
				)
			default:
				<>
					<h1 className="font-paytone text-left text-[12px]">Bạn đang ở</h1>
					<div className="flex flex-row pb-[22px]">
						<span className="font-paytone text-[24px] pr-[4px] uppercase">Tiệm cà phê sữa đá</span>
					</div>
				</>
		}
	}

	if (isLoading) {
		return (
			<div>
				<Loader size={48} />
			</div>
		)
	}

	if (itemDetail.nftID) {
		return <UnboxSuccess itemId={itemDetail.nftID} />
	}

	return (
		<>
			<Header />
			<div className="px-[16px] pt-[38px]">
				{renderHeader()}
				<ItemCard item={data} />
				<div className="pt-[49px] text-[13px] leading-[16.25px] text-center px-[48px]">
					{data.code === 'thantich1' ? t('claimDescriptionThanTich') : t('claimDescription')}
				</div>
				<div className="flex flex-row pt-[12px] justify-center">
					<Input
						placeholder={t("enterPhone")}
						value={phone}
						onChange={setPhone}
						inputClassName="w-[232px]"
						disabled={phoneNumber !== ""}
					/>
				</div>
				{error && (
					<div className="flex flex-row pt-[4px] justify-center">
						<span className="text-[14px] text-[#E60016]">{error}</span>
					</div>
				)}
				<div className="flex flex-row pt-[62px] justify-center">
					<Button className="w-[232px]" onClick={token ? handleClaim : handleLogin} loading={loading} disabled={phone.length === 0}>
						{t('claim')}
					</Button>
				</div>
				<div className="flex justify-center py-[8px]">
					Mách bạn <button className="px-[4px] underline" onClick={() => setShowInstructionModal(true)}>cách khui quà</button> nè!
				</div>
				<QRModal
					code={otpCode}
					senderNumber={phone}
					phoneNumber={authPhone}
					isOpen={showQRModal}
					onClose={() => setShowQRModal(false)}
				/>
				<InstructionModal
					onClose={() => setShowInstructionModal(false)}
					isOpen={showInstructionModal}
				/>
			</div>
		</>
	)
}