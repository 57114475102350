import Input from "../Input";
import Button from "../Button";
import {useTranslation} from "react-i18next";
import {useState} from "react";
import { addZero, isPhoneNumber } from "../../utils/string";
import { useNavigate } from "react-router-dom";
import VietNamIcon from '../../assets/vietnam.svg'
export function SearchPhoneNumber({className="!bg-red"}:{className?: string}) {
  const navigate = useNavigate()
  const {t}= useTranslation()
  const [phone, setPhone] = useState('')

  const handleView = () => {
    if (isPhoneNumber(addZero(phone))) {
      navigate(`/profile/${addZero(phone)}`)
      setPhone('')
    }
  }

  return (
    <div
      className={`flex flex-col items-center pt-[100px] pb-[21px] bg-[#F9F6F3] w-full ${className}`}
    >
      <h1 className={"uppercase mb-6 font-paytone"}>
        Nhập số bàn để xem BST
      </h1>
      <Input
        placeholder={t("enterPhone")}
        value={phone}
        onChange={setPhone}
        className="mb-8 bg-[#FFFFFF] px-[17px] shadow-[0px_0px_12px_0px_#0000001A]"
        inputClassName="[w-[160px] "
        prefix={() => {
          return (
            <img src={VietNamIcon} alt="" className="w-5 h-5"/>
          )
        }}
      />
      <Button onClick={handleView} className={"w-[230px]"} disabled={!phone}>View</Button>
    </div>
  );
}
