import { useInfiniteQuery } from "@tanstack/react-query"
import { getPortfolio } from "../services/collections"

export const usePortfolio = (phoneNumber: string) => {
  const {data, fetchNextPage, isFetching} = useInfiniteQuery({
    queryKey: ['fetchPortfolio-infinite', phoneNumber],
    queryFn: async ({pageParam = 1}) => {
      const rs = await getPortfolio(phoneNumber, pageParam)
      return rs.data || []
    },
    getNextPageParam: (lastPage, pages) => {
      const nextPageParam = !lastPage || lastPage.length === 0 ? undefined : pages.length + 1
      return nextPageParam
    },
    initialPageParam: 1
  })

  return {
    items: data,
    isFetching,
    fetchNextPage
  }
}